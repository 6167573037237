import { Row, Col, Form, ButtonToolbar, Button, Modal, ListGroup, Spinner } from "react-bootstrap"
import { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { ItemDetails } from "../../../../../../models/Account";
import { IActionResult, IAppState } from "../../../../../../redux/storeTypes";
import {
    getExternalItemRefernce, calculateConvenienceFee, resetConvenienceFeeAction,
    schedulePayment, SCHEDULE_PAYMENT_REQUEST, SCHEDULE_PAYMENT_SUCCESS, SCHEDULE_PAYMENT_FAILURE, CALCULATE_CONVENIENCE_FEE, CALCULATE_CONVENIENCE_FEE_SUCCESS, CALCULATE_CONVENIENCE_FEE_FAILURE
} from "../../../../../../redux/actions/account";
import { Schedule, ScheduledPortionType, PreFlightEmailScheduleType } from "../../../../../../models/Schedule"
import { sendSuccessToastAction, sendErrorToastAction } from "../../../../../../redux/actions/toast";
import store from "../../../../../../redux/store";
import { handleEmail, handlePayLoad } from './Payload';
import { Order, PaymentType } from "../../../../../../models/Order";
import { User } from "../../../../../../models/User";
import moment from "moment";
import Table from '../../../../../../components/Table';
import { Client, Department, PaymentChannel } from "../../../../../../models/Client";
import FormHeaderConfirmation from "../../../../../../components/FormHeaderConfirmation";
import icoError from "../../../../../../assets/img/icons/ico-error-outline.svg";
import HeaderBar from "../../../../../../components/HeaderBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";

interface IItemSearch {
    searchPlaceholder: string,
    accountIdLabel: string,
    ownersLabel: string,
    addressLabel: string,
    amountDueLabel: string,
    actionLabel: string,
    paymentDateLabel: string,
    paymentOptionLabel: string,
    convenienceFeeLabel: string,
    paymentMethodLabel: string,
    paymentReminderDateLabel: string,
    emailAddressLabel: string,
    emailAddressPlaceholder: string,
    headerBackgroundColor: string,
    calculatedConvenienceFee: Order,
    errorMessage: string,
    itemDetails: ItemDetails,
    userDetails: User,
    actionResult: IActionResult,
    isFetching: boolean,
    itemSearchLoading: boolean,
    isSaving: boolean,
    currentSchedule: any,
    client: Client,
    department: Department,
    paymentChannel: PaymentChannel,
    scheduledItems: any,
}

export const SEARCH_PLACEHOLDER = 'Search for Account ID'
export const ACCOUNT_ID_LABEL = 'Account ID';
export const OWNERS_LABEL = 'Owners';
export const ADDRESS_LABEL = 'Address';
export const AMOUNT_DUE_LABEL = 'Amount Due';
export const ACTIONS_LABEL = 'Actions';
export const PAYMENT_DATE_LABEL = 'Select Future Payment Date';
export const PAYMENT_OPTION_LABEL = 'Select Payment Option';
export const CONVENIENCE_FEE_LABEL = 'Convenience Fee'
export const PAYMENT_METHOD_LABEL = 'Select Payment Method';
export const PAYMENT_REMINDER_DATE_LABEL = 'Select Payment Reminder';
export const EMAIL_ADDRESS_LABEL = 'Email Address';
export const EMAIL_ADDRESS_PLACEHOLDER = 'Enter Email Address'
export const HEADER_BACKGROUND_COLOR = 'rgb(250, 250, 250)';
export const HEADER_PADDING = '0';


const checkStyleForDefault = (style: string, defaultStyle: string) => {
    return style && style !== '' ? style : defaultStyle;
}

export const printOnlyModal = () => {
    document.getElementById("nonPrintable")!.className = "";
    document.getElementById("nonPrintable")!.className += "noPrint";
    window.print();
}

const ItemSearch = ({ searchPlaceholder, accountIdLabel, ownersLabel, addressLabel, amountDueLabel, actionLabel, convenienceFeeLabel,
    paymentDateLabel, paymentOptionLabel, paymentMethodLabel, headerBackgroundColor, calculatedConvenienceFee, errorMessage, actionResult, itemDetails,
    paymentReminderDateLabel, emailAddressLabel, emailAddressPlaceholder, userDetails, currentSchedule, isSaving, isFetching, itemSearchLoading, client, department, paymentChannel, scheduledItems }: IItemSearch) => {

    const actionToken = "ItemSearch";
    const dispatch = useDispatch();
    const daysDiffernce = (selectedDate: any, todaysDate: any) => { return Math.ceil(Math.abs(selectedDate - todaysDate) / (1000 * 60 * 60 * 24)) };

    const [scheduleModal, showScheduleModal] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState<string>('');
    const [data, setData] = useState<any>([]);
    const [rowData, setRowData] = useState<any>([]);
    const [validated, setValidated] = useState(false);
    const [paymentDate, setPaymentDate] = useState<string>('');
    const [paymentType, setPaymentType] = useState<PaymentType>();
    const [paymentOption, setPaymentOption] = useState<any>();
    const [paymentDetails, setPaymentDetails] = useState<any>([]);
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [paymentReminderOptions, setPaymentReminderOptions] = useState<any>([]);
    const [itemId, setItemId] = useState<string>('');
    const [delinquenModal, setDelinquenModal] = useState<boolean>(false);
    const [userSchedules, setUserSchedules] = useState<any>([]);
    const [customErrorMessage, setCustomErrorMessage] = useState<string>('');
    const [agreeToAchTerms, setAgreeToAchTerms] = useState<boolean>(false);
    const [calculationError, setCalculationError] = useState<boolean>(false);

    if (!searchPlaceholder) { searchPlaceholder = SEARCH_PLACEHOLDER }
    if (!accountIdLabel) { accountIdLabel = ACCOUNT_ID_LABEL }
    if (!ownersLabel) { ownersLabel = OWNERS_LABEL; }
    if (!addressLabel) { addressLabel = ADDRESS_LABEL; }
    if (!amountDueLabel) { amountDueLabel = AMOUNT_DUE_LABEL; }
    if (!actionLabel) { actionLabel = ACTIONS_LABEL; }
    if (!paymentDateLabel) { paymentDateLabel = PAYMENT_DATE_LABEL; }
    if (!paymentOptionLabel) { paymentOptionLabel = PAYMENT_OPTION_LABEL; }
    if (!paymentMethodLabel) { paymentMethodLabel = PAYMENT_METHOD_LABEL; }
    if (!convenienceFeeLabel) { convenienceFeeLabel = CONVENIENCE_FEE_LABEL; }
    if (!paymentReminderDateLabel) { paymentReminderDateLabel = PAYMENT_REMINDER_DATE_LABEL; }
    if (!emailAddressLabel) { emailAddressLabel = EMAIL_ADDRESS_LABEL; }
    if (!emailAddressPlaceholder) { emailAddressPlaceholder = EMAIL_ADDRESS_PLACEHOLDER; }

    useEffect(() => {
        if (itemDetails) {
            setData(itemDetails);
        }
    }, [itemDetails]);

    useEffect(() => {
        if (scheduledItems) {
            setUserSchedules(scheduledItems);
        }
    }, [scheduledItems]);

    useEffect(() => {
        if (userDetails) {
            setPaymentDetails(userDetails);
            setEmailAddress(userDetails.email);
        }
    }, [userDetails]);

    useEffect(() => {
        if (currentSchedule && itemId) {
            dispatch(getExternalItemRefernce(itemId, actionToken));
        }
    }, [currentSchedule]);

    useEffect(() => {
        if (actionResult && actionResult.result) {
            if (actionResult.type === CALCULATE_CONVENIENCE_FEE) {
                setCalculationError(false);
                if (actionResult.result === CALCULATE_CONVENIENCE_FEE_SUCCESS) {
                    setCalculationError(false);
                } else if (actionResult.result === CALCULATE_CONVENIENCE_FEE_FAILURE) {
                    setCalculationError(true);
                }
            }
            if (actionResult.type === SCHEDULE_PAYMENT_REQUEST) {
                if (actionResult.result === SCHEDULE_PAYMENT_SUCCESS) {
                    dispatch(sendSuccessToastAction("Scheduled payment has been successfully added"));
                    showScheduleModal(false);
                } else if (actionResult.result === SCHEDULE_PAYMENT_FAILURE) {
                    dispatch(sendErrorToastAction("Scheduled payment could not be saved."));
                }
            }
        }
    }, [actionResult]);


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });


    const scheduleFormatter = (cell: any, row: any) => {
        const scheduledValidation = row?.itemSchedules.map((item: any) => { if (item.paymentScheduleStatus != 'Error') { return item.scheduledPortionType } });

        const delinquenFlag = (row?.metaData?.DelinquenFlag === "Y")
        if (['FirstHalf', 'SecondHalf'].every((item: any) => scheduledValidation.includes(item))) {
            return (<span>Scheduled</span>)
        }
        if (['Full'].every((item: any) => scheduledValidation.includes(item))) {
            return (<span>Scheduled</span>)
        }
        if (delinquenFlag) {
            return (<span ><a style={{ color: 'rgb(245, 34, 45)' }} onClick={() => { setDelinquenModal(true) }}>Delinquent</a></span>)
        }
        else {
            return (
                <span>
                    <a
                        onClick={() => {
                            showScheduleModal(true);
                            setRowData(row);
                            setPaymentType(PaymentType.Unknown);
                            setPaymentOption(ScheduledPortionType.Unknown);
                            setPaymentDate('');
                            setCustomErrorMessage('');
                            setValidated(false);
                        }}>
                        Schedule
                    </a>
                </span>
            )
       }
    }

    const addressFormatter = (cell: any) => {
        const address = [cell?.Address1?.trim(), cell?.Address2?.trim(), cell?.State?.trim(), cell?.Zip?.trim()].filter(Boolean).join(", ");
        return (
            <span>{address}</span>
        )
    }

    const amountFormatter = (cell: any) => {
        return (<span>{formatter.format(cell)}</span>)
    }

    const columns = [{
        dataField: 'metaData.AccountNumber',
        text: accountIdLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true
    }, {
        dataField: 'metaData.OwnerName',
        text: ownersLabel,
        editable: false,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        sort: true,
        searchable: false
    }, {
        dataField: 'metaData',
        text: addressLabel,
        formatter: addressFormatter,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        searchable: false
    }, {
        dataField: 'fixedAmount',
        text: amountDueLabel,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        formatter: amountFormatter,
        searchable: false
    }, {
        dataField: 'id',
        text: actionLabel,
        formatter: scheduleFormatter,
        headerStyle: {
            backgroundColor: checkStyleForDefault(headerBackgroundColor, HEADER_BACKGROUND_COLOR)
        },
        editable: false,
        searchable: false
    }];

    const setErrorToastAction = (selectedOption: any, dueDate: string, customMessage?: string) => {
        setPaymentDate('');
        setPaymentType(PaymentType.Unknown);
        setPaymentOption(ScheduledPortionType.Unknown);
        setPaymentReminderOptions([]);
        customMessage ?
            setCustomErrorMessage(`${customMessage}`) :
            setCustomErrorMessage(`Cannot schedule a ${ScheduledPortionType[selectedOption].replace(/([A-Z])/g, ' $1').trim()} payment after the due date, i.e. ${moment(dueDate).format('MM/DD/YYYY')}`);

    }

    const calculateConveniceFee = (e: any) => {
        const selectedOption = e.target.value;
        setPaymentOption(selectedOption);
        if (selectedOption == ScheduledPortionType.FirstHalf && new Date(paymentDate) > new Date(rowData?.metaData?.DateRangeFirstPO)) {
            setErrorToastAction(selectedOption, rowData?.metaData?.DateRangeFirstPO)
            return false;
        }
        if (selectedOption == ScheduledPortionType.SecondHalf) {
            if (new Date(paymentDate) > new Date(rowData?.metaData?.DateRangeSecondPO)) {
                setErrorToastAction(selectedOption, rowData?.metaData?.DateRangeSecondPO)
                return false;
            }
            const firstHalfScheduledDate = userSchedules?.map((item: any) =>
                (item.scheduledPortionType === "FirstHalf" && item?.item?.externalItemReference === rowData?.metaData?.AccountNumber) ? item?.paymentWorkItem?.nextRun : null).filter(Boolean);

            if (firstHalfScheduledDate && new Date(paymentDate) < new Date(firstHalfScheduledDate)) {
                setErrorToastAction(selectedOption, rowData?.metaData?.DateRangeSecondPO, 'Second Half payment cannot be scheduled before the First Half payment')
                return false;
            }
        }
        if (selectedOption == ScheduledPortionType.Full && new Date(paymentDate) > new Date(rowData?.metaData?.DateRangeFullPO)) {
            setErrorToastAction(selectedOption, rowData?.metaData?.DateRangeFullPO)
            return false;
        }

        const amount = selectedOption == ScheduledPortionType.FirstHalf ? rowData?.metaData.FirstHalfDue
            : selectedOption == ScheduledPortionType.SecondHalf ? rowData?.metaData.SecondHalfDue
                : selectedOption == ScheduledPortionType.Full ? rowData?.metaData.GrandTotal
                    : selectedOption;

        if (amount == 0) {
            setPaymentOption(ScheduledPortionType.Unknown);
            setCustomErrorMessage(`Cannot schedule a $0.00 payment`);
            return false;
        }

        const ConvenienceFee = {
            paymentType: paymentType,
            methodName: 'Sale',
            cardType: paymentType == PaymentType.CreditCard ? userDetails?.cards && userDetails?.cards[0]?.internalCardType : 'ElectronicCheck',
            orderLines: [{ "ItemReferenceNumber": rowData?.metaData.AccountNumber, "ItemId": rowData?.msbId, "UnitPrice": amount, "Quantity": 1 }]
        }
        dispatch(calculateConvenienceFee(ConvenienceFee, actionToken));

    }

    const handlePaymentDateChange = (e: any) => {
        const selectedDate = e.target.value;
        const presentDate = new Date();
        setPaymentType(PaymentType.Unknown);
        setPaymentOption(ScheduledPortionType.Unknown);
        if (new Date(selectedDate) < presentDate) {
            setPaymentDate('');
            setCustomErrorMessage(`Scheduled payments must be after ${moment(presentDate).format('MM/DD/YYYY')}`);
            return false;
        }
        setPaymentDate(e.target.value);
        setCustomErrorMessage('');
        const maximumDays = daysDiffernce(new Date(selectedDate), new Date());
        const reminderOptions = Array.from({ length: maximumDays }, (_, i) => i + 1).slice(0, 7);
        setPaymentReminderOptions(reminderOptions);
    }

    const handlePaymentType = (e: any) => {
        setPaymentOption(ScheduledPortionType.Unknown);
        setPaymentType(e.target.value);
    }

    const dynamicOptions = paymentReminderOptions.map((item: any) => {
        return (
            <option key={item} value={PreFlightEmailScheduleType[item]}>
                {item === 1 ? `${item} day before` : `${item} days before`}
            </option>

        )
    })

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() !== false) {
            let schedule = new Schedule();
            const paymentReminderDate = form.elements.paymentReminderDate.value;

            schedule.userIdentifier = userDetails.msbId;
            schedule.itemIdentifier = rowData?.msbId;

            const dateFormat = new Date(paymentDate);
            dateFormat.setHours(dateFormat.getHours() + 4);
            schedule.paymentDate = dateFormat.toISOString();

            schedule.recipientAddresses = [emailAddress];
            schedule.paymentOption = ScheduledPortionType[paymentOption]!;
            schedule.preFlightEmailScheduleType = paymentReminderDate ? paymentReminderDate : PreFlightEmailScheduleType.NoReminder;
            schedule.paymentType = paymentType!;
            schedule.paymentRequestHeaders.ClientId = paymentChannel.department.client.msbId!;
            schedule.paymentRequestHeaders.DepartmentId = paymentChannel.department.msbId!;
            schedule.paymentRequestHeaders.PaymentChannelId = paymentChannel.msbId!;

            const payloadDetails = { paymentType: paymentType!, paymentOption: ScheduledPortionType[paymentOption]!, calculatedConvenienceFee: calculatedConvenienceFee, userDetails: userDetails, emailAddress: emailAddress }
            const payLoad = handlePayLoad(payloadDetails);
            payLoad.clientId = paymentChannel.department.client.msbId!;
            payLoad.departmentId = paymentChannel.department.msbId!;
            payLoad.paymentChannelId = paymentChannel.msbId;

            schedule.orderRequestPayload = payLoad;

            if (paymentReminderDate != PreFlightEmailScheduleType.NoReminder) {
                const emailDetails = {
                    accountNumber: rowData?.externalItemReference,
                    paymentType: paymentType!,
                    paymentOption: ScheduledPortionType[paymentOption],
                    paymentAmount: calculatedConvenienceFee.totalAmount,
                    userDetails: userDetails,
                    paymentDate: paymentDate,
                    paymentReminderDate: PreFlightEmailScheduleType[paymentReminderDate],
                    clientName: client?.businessName,
                    departmentDetails: department
                }
                const emailModel = handleEmail(emailDetails);
                emailModel.clientId = paymentChannel.department.client.msbId!;
                emailModel.departmentId = paymentChannel.department.msbId!;
                emailModel.paymentChannelId = paymentChannel.msbId!;
                emailModel.externalItemReference = rowData?.metaData?.AccountNumber;
                emailModel.paymentType = paymentType!;

                schedule.preFlightEmailModel = emailModel;

                dispatch(schedulePayment(schedule, actionToken));
            } else {
                const { preFlightEmailModel, ...scheduleReset } = schedule;
                dispatch(schedulePayment(scheduleReset, actionToken));
            }
        }
        setValidated(true);
    };

    const renderDynamicPaymentOptions = () => {
        const itemSchedules = rowData?.itemSchedules
        const todaysDate = new Date();
        const firstHalfRange = todaysDate <= new Date(rowData?.metaData?.DateRangeFirstPO);
        const secondHalfRange = todaysDate <= new Date(rowData?.metaData?.DateRangeSecondPO);
        const fullRange = todaysDate <= new Date(rowData?.metaData?.DateRangeFullPO);

        const firstHalfRangeOptions = (
            <>
                <option value={ScheduledPortionType.FirstHalf}>{`First Half (${formatter.format(rowData?.metaData?.FirstHalfDue)})`}</option>
                <option value={ScheduledPortionType.SecondHalf} disabled={rowData?.metaData?.FirstHalfDue == 0 ? false : true}>{`Second Half (${formatter.format(rowData?.metaData?.SecondHalfDue)})`}</option>
                <option value={ScheduledPortionType.Full}>{`Full Payment (${formatter.format(rowData?.metaData?.GrandTotal)})`}</option>
            </>
        )

        const fullRangeOptions = (
            <>
                <option value={ScheduledPortionType.SecondHalf}>{`Second Half (${formatter.format(rowData?.metaData?.SecondHalfDue)})`}</option>
                <option value={ScheduledPortionType.Full}>{`Full Payment (${formatter.format(rowData?.metaData?.GrandTotal)})`}</option>
            </>
        )

        const secondHalfRangeOption = (
            <>
                <option value={ScheduledPortionType.SecondHalf}>{`Second Half (${formatter.format(rowData?.metaData?.SecondHalfDue)})`}</option>
            </>
        )

        const defaultRules = firstHalfRange ? firstHalfRangeOptions : fullRange ? fullRangeOptions : secondHalfRange ? secondHalfRangeOption : null

        if (itemSchedules && itemSchedules.length > 0) {
            const itemSchedulesOptions = itemSchedules.map((item: any) => { if (item.paymentScheduleStatus != 'Error') { return item.scheduledPortionType } });
            const errordPayments = itemSchedules.map((item: any) => { if (item.paymentScheduleStatus === 'Error') { return item.scheduledPortionType } });

            if (['FirstHalf'].every((item: any) => itemSchedulesOptions.includes(item)) && secondHalfRange) {
                return (<option value={ScheduledPortionType.SecondHalf}>{`Second Half (${formatter.format(rowData?.metaData?.SecondHalfDue)})`}</option>)
            }
            if (['SecondHalf'].every((item: any) => itemSchedulesOptions.includes(item)) && firstHalfRange) {
                return (<option value={ScheduledPortionType.FirstHalf}>{`First Half (${formatter.format(rowData?.metaData?.FirstHalfDue)})`}</option>)
            }
            if (['FirstHalf'].every((item: any) => errordPayments.includes(item))) {
                return defaultRules
            }
            if (['SecondHalf'].every((item: any) => errordPayments.includes(item))) {
                return secondHalfRange ? secondHalfRangeOption : null
            }
            if (['FirstHalf', 'SecondHalf'].every((item: any) => errordPayments.includes(item))) {
                return defaultRules
            }
            if (['Full'].every((item: any) => errordPayments.includes(item))) {
                return defaultRules
            }
        }
        else {
            return defaultRules
        }
    }

    const renderpPaymentSchedule = () => {
        return (
            <Form id='schedulePayments' noValidate validated={validated} onSubmit={handleSubmit}>
                <ListGroup variant="flush">
                    <ListGroup.Item className="p-0">
                        <div>
                            <div className="details_title">
                                <h2>{`Add ${rowData?.metaData?.AccountNumber} Payment Schedule`}</h2>
                                <p>Below are the details for this account.</p>
                            </div>
                            <div className="details_description">
                                <h3>{accountIdLabel}</h3>
                                <span>{rowData?.metaData?.AccountNumber}</span>
                            </div>
                            <div className="details_description">
                                <h3>{ownersLabel}</h3>
                                <span>{rowData?.metaData?.OwnerName}</span>
                            </div>
                            <div className="details_description">
                                <h3>{addressLabel}</h3>
                                <span>{rowData?.metaData?.Address1}</span>
                            </div>
                            <div className="details_description">
                                <h3>{amountDueLabel}</h3>
                                <span>{amountFormatter(rowData?.fixedAmount)}</span>
                            </div>
                        </div>
                    </ListGroup.Item>
                    <hr />
                    <ListGroup.Item className="p-0">
                        <div className="details_title">
                            <h2>Payment Schedule</h2>
                            <p>Please select the future payment date, payment method and payment option.</p>
                            <p style={{ color: '#F5222D', marginTop: '8px' }}><strong>Statutory due dates:</strong></p>
                            <ul style={{ color: '#F5222D' }}>
                                <li><strong>1st Half: Last Day of February</strong></li>
                                <li><strong>Full: April 30th</strong></li>
                                <li><strong>2nd Half: June 15th</strong></li>
                            </ul>
                        </div>
                        <div>
                            {customErrorMessage.length != 0 &&
                                <Form.Group as={Row} className="mb-3">
                                    <HeaderBar backgroundColor='#FFF1F0' message={customErrorMessage} icon={icoError} />
                                </Form.Group>
                            }
                            <Form.Group as={Row} controlId="paymentDate" className="mb-3">
                                <Form.Label column>{paymentDateLabel}</Form.Label>
                                <Col>
                                    <Form.Control type="date" required onKeyDown={(e: any) => { e.preventDefault() }} value={paymentDate} onChange={handlePaymentDateChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="paymentMethod" className="mb-3">
                                <Form.Label column>{paymentMethodLabel}</Form.Label>
                                <Col>
                                    <Form.Control as='select' required value={paymentType} onChange={handlePaymentType} disabled={!paymentDate}>
                                        <option value="">Select payment method</option>
                                        {paymentDetails?.cards && paymentDetails?.cards.length > 0 && <option value={PaymentType.CreditCard}>Credit Card</option>}
                                        {paymentDetails?.bankAccounts && paymentDetails?.bankAccounts.length > 0 && <option value={PaymentType.ECheck}>eCheck</option>}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="paymentOption" className="mb-3">
                                <Form.Label column>{paymentOptionLabel}</Form.Label>
                                <Col>
                                    <Form.Control as="select" required value={paymentOption} onChange={calculateConveniceFee} disabled={!paymentType || !paymentDate}>
                                        <option key={0} value="" >Select payment option</option>
                                        {renderDynamicPaymentOptions()}
                                    </Form.Control>
                                </Col>
                            </Form.Group>
                            {!isFetching ? <>
                                {calculatedConvenienceFee && paymentOption != ScheduledPortionType.Unknown &&
                                    <Form.Group as={Row} controlId="ConvenieceFee" className="mb-3 d-flex align-content-center">
                                        <Form.Label column>{convenienceFeeLabel}</Form.Label>
                                        <Col>
                                            <span>{formatter.format(calculatedConvenienceFee.convenienceFee)}</span>
                                        </Col>
                                    </Form.Group>
                                } </> : <div style={{ textAlign: 'center', marginTop: '1.5rem' }} ><Spinner animation="border" /> </div>}
                            <p style={{ color: '#F5222D', marginTop: '24px', marginBottom: '8px' }}><strong>Future payments can only be scheduled on or before statutory due dates. For a same day payment, logout and click the "Make Payment" button.</strong></p>
                            <p style={{ color: '#141414' }}><strong>Returned payments will be charged a $20 fee. Late payments will incur late interest of 1% per month.</strong></p>
                        </div>
                    </ListGroup.Item>
                    <hr />
                    <ListGroup.Item className="p-0">
                        <div className="details_title">
                            <h2>Payment Reminder Notification</h2>
                            <p>Please select how many days to be notified before your scheduled payment and enter in your email address below to send payment reminders to.</p>
                        </div>
                        <div>
                            <Form.Group as={Row} controlId="paymentReminderDate" className="mb-3">
                                <Form.Label column>{paymentReminderDateLabel}</Form.Label>
                                <Col>
                                    <Form.Control as="select" disabled={!paymentDate} >
                                        <>
                                            <option value={PreFlightEmailScheduleType.NoReminder}>No reminder</option>
                                            {dynamicOptions}
                                        </>
                                    </Form.Control>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="email" className="mb-3">
                                <Form.Label column>{emailAddressLabel}</Form.Label>
                                <Col>
                                    <Form.Control required maxLength={50} type="email" placeholder={emailAddressPlaceholder} value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                                    <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                                </Col>
                            </Form.Group>

                            {(paymentType == PaymentType.ECheck) && paymentOption ?
                                <Form.Group as={Row} className="mb-3" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" disabled={isSaving} checked={agreeToAchTerms} onChange={(e) => setAgreeToAchTerms(e.target.checked)}
                                        label={
                                            <span>
                                                By checking here, I authorize Municipal Services Bureau to initiate a single ACH electronic debit to my account ending in {userDetails?.bankAccounts && userDetails?.bankAccounts[0]?.accountNumber?.substr(-4)} in the amount 
                                                of {formatter.format(calculatedConvenienceFee?.totalAmount)} on {moment(paymentDate).format('MM/DD/YYYY')}.
                                                I agree that ACH transactions I authorize comply with all applicable laws.
                                                Once payment is authorized, there cannot be any changes or corrections. I understand that I may call during normal business hours if I have any questions.
                                                It is recommended that you {<a onClick={() => printOnlyModal()} href="#">print</a>} a copy of this authorization and maintain it for your records.
                                            </span>
                                        } />
                                </Form.Group>
                                : null
                            }

                            <Form.Group controlId="saveSchedule" className="form-footer mt-5 mb-0 d-print-none">
                                <Button form='schedulePayments' type="submit" disabled={!calculatedConvenienceFee || isSaving || isFetching || (paymentType == PaymentType.ECheck && !agreeToAchTerms)} >
                                    Save
                                </Button>
                                <Button form='schedulePayments' style={{ marginLeft: 'auto' }} variant="link" disabled={isSaving}
                                    onClick={() => { dispatch(resetConvenienceFeeAction()); showScheduleModal(false); setEmailAddress(userDetails?.email) }}>
                                    Cancel
                                </Button>
                            </Form.Group>

                            {(calculationError && errorMessage) ?
                                <div className="alert alert-danger mb-0 mt-2 d-flex align-items-start" role="alert" id="paymentErrorBox">
                                    <FontAwesomeIcon icon={faTimesCircle} size="sm" />{errorMessage}
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </Form>
        )
    }

    const handleTableSubmit = (event: any) => {
        event.preventDefault();
        const form = event.currentTarget;
        let itemReference;
        if (form.elements.externalItemReference && form.elements.externalItemReference.value.length > 0) {
            itemReference = form.elements.externalItemReference.value;
        }
        setItemId(itemReference);
        dispatch(getExternalItemRefernce(itemReference, actionToken));
    };

    const doResetSearch = () => {
        setData([]);
        setSearchValue('');
    };

    const renderTable = () => {
        if (itemSearchLoading) {
            return (<div style={{ textAlign: 'center'}} ><Spinner animation="border" /> </div>);
        } else {
            return (
                <Table
                    keyField="msbId"
                    data={data}
                    columns={columns}
                    remote={false}
                />);
        }
    };

    return (
        <>
            <Modal show={scheduleModal} id="section-to-print" size="lg" onHide={() => showScheduleModal(false)} backdrop="static">
                <Modal.Header closeButton />
                <Modal.Body>
                    {renderpPaymentSchedule()}
                </Modal.Body>
            </Modal>
            <Modal show={delinquenModal} className="modal-confirmation">
                <Modal.Body>
                    <FormHeaderConfirmation iconImg={icoError} title="Delinquent taxes on account" />
                    <div className="confirmation-body">
                        <p>There are delinquent taxes on this account and a payment cannot be scheduled. Please contact the Treasurer's Office.</p>
                        <div style={{ marginTop: '16px' }}>
                            <p>
                                <strong>Phone: </strong>
                                <span> 303-660-7455</span>
                            </p>
                            <p>
                                <strong>Email: </strong>
                                <span> dctreasurer@douglas.co.us</span>
                            </p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
                            <strong> Address:</strong>
                            <span>100 Third Street</span>
                            <span>Castle Rock, CO 80104</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px' }}>
                            <strong>Hours:</strong>
                            <span>Monday - Friday </span>
                            <span> 8:00 a.m. - 5:00 p.m.</span>
                        </div>
                        <Form.Group className="confirmation-footer">
                            <Button onClick={() => setDelinquenModal(false)}>
                                Close
                            </Button>
                        </Form.Group>
                    </div>
                </Modal.Body>
            </Modal>
            <Row>
                <Col>
                    <Form className="table-search mb-4" onSubmit={handleTableSubmit}>
                        <Row>
                            <Col xl={9} lg={9} md={12} sm={12}>
                                <Form.Group controlId="externalItemReference">
                                    <Form.Control type="text" placeholder={searchPlaceholder} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col xl={3} lg={3} md={12} sm={12}>
                                <ButtonToolbar>
                                    <Button variant="outline-secondary" onClick={doResetSearch} >Reset</Button>
                                    <Button type="submit" className="ms-0">Search</Button>
                                </ButtonToolbar>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    {renderTable()}
                </Col>
            </Row>
        </>
    )
}


const mapStateToProps = (state: IAppState) => {
    return {
        isFetching: state.account.isFetching,
        itemSearchLoading: state.account.itemSearchLoading,
        isSaving: state.account.isSaving,
        itemDetails: state.account.itemDetails,
        actionResult: state.account.actionResult,
        currentSchedule: state.account.currentSchedule,
        userDetails: state.account.user,
        calculatedConvenienceFee: state.account.order,
        errorMessage: state.account.errorMessage,
        scheduledItems: state.account.scheduledItems,
        client: state.clients.client,
        department: state.clients.department,
        paymentChannel: state.paymentChannelWebApplication.paymentChannel
    };
};

export default connect(mapStateToProps)(ItemSearch);
